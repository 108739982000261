import * as React from "react"

import Layout from "../components/Layout/Layout"
import SeoHead from "../components/Scripts/SeoHead"

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export const Head = () => <SeoHead title="404: Not Found" />

export default NotFoundPage
